<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import { instance } from '../../src/request/app-axios.js'

export default {
  data () {
    return {
      companyAddresses: [],
      states: [],
      addressTypes: [],
      companyAddress: {}
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getCompanyAddresses (companyID) {
      await axios
        .get(`${BeaconapiUrl}/api/company-address`, {
          params: { companyID: companyID }
        })
        .then(result => {
          this.companyAddresses = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async addCompanyAddress (companyAddress) {
      await instance
        .post(`${BeaconapiUrl}/api/company-address`, companyAddress)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(`Successfully Added ${companyAddress.addressLine1} ${companyAddress.addressLine2}`)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async updateCompanyAddress (companyAddress) {
      companyAddress.lastModifiedByID = localStorage.getItem('employeeID')

      await axios
        .put(`${BeaconapiUrl}/api/company-address/${companyAddress.companyAddressID}`, companyAddress)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(`Successfully updated address ${companyAddress.addressLine1} ${companyAddress.addressLine2}`)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async deleteCompanyAddress (companyAddressID) {
      await axios
        .delete(`${BeaconapiUrl}/api/company-address/${companyAddressID}`)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAddressTypes () {
      await axios
        .get(`${BeaconapiUrl}/api/company-address/address-type`)
        .then(result => {
          this.addressTypes = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getStates () {
      await axios
        .get(`${BeaconapiUrl}/api/company-address/state`)
        .then(result => {
          this.states = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getUSStates () {
      await axios
        .get(`${BeaconapiUrl}/api/company-address/us-state`)
        .then(result => {
          this.states = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getCompanyAddressByCompanyAddressID (companyAddressID) {
      await axios
        .get(`${BeaconapiUrl}/api/company-address/${companyAddressID}`)
        .then((result) => {
          this.companyAddress = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async getAvailableAddressTypesForAddress (companyAddressID, companyID) {
      await axios
        .get(`${BeaconapiUrl}/api/company-address/available-address-types-for-address`, { params: { companyAddressID: companyAddressID, companyID: companyID } })
        .then((result) => {
          this.addressTypes = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    },
    async getAvailableAddressTypesForCompany (companyID) {
      await axios
        .get(`${BeaconapiUrl}/api/company-address/available-address-types-for-company`, { params: { companyID: companyID } })
        .then((result) => {
          this.addressTypes = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    }
  }
}
</script>
